<script setup lang="ts">
import { useOrderStore, useUserStore } from "@/store";
import { formatDate, formatTimeRange } from "@/utils/general";

const orderStore = useOrderStore();
const userStore = useUserStore();

// Return length directly with a fallback to 0
const totalAssetsFilled = () => {
  return (
    orderStore.getCurrentOrderAssets?.filter(
      (asset) => asset?.quantity_dispensed !== null
    ).length || 0
  );
};
</script>

<template>
  <div class="card card-compact shadow-md w-full bg-base-100 text-base-content">
    <div class="card-content">
      <div class="grid grid-cols-2 gap-2 items-center justify-between p-2">
        <div class="flex flex-col">
          <p class="font-bold text-gray-700">Order Code:</p>
          <p class="text-gray-600">
            {{ orderStore.currentOrder.customer_order.order_code || "N/A" }}
          </p>
        </div>
        <div class="flex flex-col">
          <p class="font-bold text-gray-700">Vehicle No.:</p>
          <p class="text-gray-600">
            {{
              userStore.getVehicleDetails()?.registration_number ||
              userStore.getVehicleDetails()?.description
            }}
          </p>
        </div>
        <div class="flex flex-col">
          <p class="font-bold text-gray-700">Total Qty Dispensed:</p>
          <p class="text-gray-600">{{ orderStore.getDispensedQty || 0 }}L ({{ totalAssetsFilled() }})</p>
        </div>
        <!-- Order Details Section -->
        <div class="flex flex-col"
          v-if="orderStore.currentOrder.customer_order.product_variation_partner_localities_slot">
          <p class="font-bold text-gray-700">Slot Date:</p>
          <p class="text-gray-600">
            {{ formatDate(orderStore.currentOrder.customer_order.product_variation_partner_localities_slot.day_date) }}
            {{ formatTimeRange(orderStore.currentOrder.customer_order.product_variation_partner_localities_slot) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>