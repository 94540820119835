import dayjs from "dayjs";
import { DateTime } from "luxon";

export const delay = async (interval: number) => {
	return new Promise((resolve) => setTimeout(resolve, interval));
};

export const DATEFORMATS = {
	DATE: "DD-MM-YYYY",
	TIME: "hh:mm A",
	DATETIME: "DD/MM/YYYY hh:mm A",
};

export const getTimeFormatted = (
	time: string,
	format: keyof typeof DATEFORMATS,
) => {
	return dayjs(time).format(DATEFORMATS[format]);
};

export const getDate = (date: string) => {
	return dayjs(date).format("DD-MM-YYYY");
};

export const getTime = (date: string) => {
	return dayjs(date).format("hh:mm:ss A");
};

export const getTimeElapsed = (startTime: number) => {
	const start = dayjs(startTime || Date.now());
	const current = dayjs();
	const elapsed = current.diff(start, "second");

	if ((elapsed ?? 0) < 180) {
		return `${elapsed?.toFixed(0) ?? 0}s`;
	} else {
		const minutes = Math.floor((elapsed ?? 0) / 60);
		const seconds = (elapsed ?? 0) % 60;
		return `${minutes}m ${seconds.toFixed(0)}s`;
	}
};

export const getConcatenatedName = (user: any): string => {
	if (!user) return "FuelBuddy User";

	const { first_name, middle_name, last_name } = user;
	return `${first_name}${middle_name ? " " + middle_name : ""}${
		last_name ? " " + last_name : ""
	}`;
};

export const getInitials = (user: any): string => {
	if (!user) return "NA";

	const { first_name, middle_name, last_name } = user;
	const initials =
		first_name.charAt(0).toUpperCase() +
		(last_name?.charAt(0).toUpperCase() ||
			middle_name?.charAt(0).toUpperCase() ||
			first_name.charAt(1)?.toUpperCase() ||
			"A");

	return initials;
};

/**
 * This function generates random RGB values and then converts them into pastel colors by averaging each RGB value with 255 (white). Finally, it converts the RGB values to hexadecimal format and returns the result as a string
 * @returns hexadecimal color string
 */
export const generateRandomPastelColor = (): string => {
	// Generate random RGB values
	const r = Math.floor(Math.random() * 256);
	const g = Math.floor(Math.random() * 256);
	const b = Math.floor(Math.random() * 256);

	// Convert to pastel colors
	const pastelR = Math.floor((r + 255) / 2);
	const pastelG = Math.floor((g + 255) / 2);
	const pastelB = Math.floor((b + 255) / 2);

	// Convert RGB to hexadecimal
	const hexColor =
		"#" +
		((1 << 24) + (pastelR << 16) + (pastelG << 8) + pastelB)
			.toString(16)
			.slice(1);

	return hexColor;
};

export const getUserName = (user: any) => {
	if (!user) return "FuelBuddy User";
	return (
		user.first_name +
		(user.middle_name ? " " + user.middle_name : "") +
		(user.last_name ? " " + user.last_name : "")
	);
};

export const validateEmail = (email: string): boolean => {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return emailRegex.test(email);
};

export const getPreciseFloatValue = (val: number, precision = 2) => {
	if (val === null || val === undefined || isNaN(val)) return val;
	return parseFloat(Number(val).toFixed(precision));
};

export const formatTimeRange = (slot: any) => {
	if (!slot) return "";

	const formatTime = (time: string) =>
		time ? DateTime.fromISO(time).toUTC().toFormat("hh:mm a") : "";

	const startTime = formatTime(slot?.start_time);
	const endTime = formatTime(slot?.end_time);

	return [startTime, endTime].filter(Boolean).join(" - ");
};

export const getFullName = (user: any) => {
	if (!user) return "";

	const { first_name = "", middle_name = "", last_name = "" } = user;

	// Handle null values by treating them as empty strings
	const parts = [
		first_name || "",
		middle_name || "",
		last_name || "",
	];

	// Filter out empty strings and join the non-empty parts with a space
	return parts.filter((namePart) => namePart.trim()).join(" ");
};

export const formatDate = (
	date: any,
	format = "yyyy-MM-dd",
): string => {
	let dateTime;

	if (date instanceof Date) {
		dateTime = DateTime.fromJSDate(date);
	} else if (typeof date === "string") {
		dateTime = DateTime.fromISO(date);
	} else if (typeof date === "number") {
		dateTime = DateTime.fromMillis(date);
	} else {
		throw new Error(
			"Unsupported date format. Please provide a Date object, ISO string, or timestamp.",
		);
	}

	return dateTime.toFormat(format);
};
